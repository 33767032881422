import React from 'react';

export const Tunelyzer = () => {
    return (
        <>
            <p>Tunelyzer on the App Store: <a href="appstore.com">here</a></p>
        </>
    )
}

export default Tunelyzer;